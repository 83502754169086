import React from "react";
import {createTheme, ThemeProvider} from "@material-ui/core";

const theme = createTheme(
  {
    typography: {
      fontFamily: ["Poppins"].join(",")
    },

    palette: {
      primary: {
        main: "#3899FF",
      },
      secondary: {
        main: "#3783e7",
      },
      error: {
        main: "#dc3545",
      }
    },
    props: {
      MuiButtonBase: {
        disableRipple: false
      },
      MuiPopover: {
        elevation: 1
      }
    },
    overrides:{
      MuiTextField:{
        root:{
          border: '0',
          borderRadius: '8px',
          padding: '7px',
          fontSize: '13px'
        }
      },
      MuiOutlinedInput: {
        root: {
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            border: 0
          },
          '&.Mui-focused fieldset': {
            border: 0
          },
        },
      },
      MuiButton:{
        containedPrimary:{
          color: "white"
        }
      }
    }
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
