import React from "react";

const LockIcon = ({ show }) => {
  if (!show) return null;

  return (
    <span className="text-muted ms-2 mt-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-lock-fill"
        viewBox="0 0 16 16"
      >
        <path d="M3.5 7V4a4 4 0 0 1 8 0v3h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h1zm1 0h6V4a3 3 0 1 0-6 0v3z" />
      </svg>
    </span>
  );
};

export default LockIcon;