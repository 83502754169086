import React, {useContext, useEffect, useMemo, useState} from "react";
import objectPath from "object-path";
import { useLocation } from 'react-router-dom'
import {useHtmlClassService} from "../_core/MetronicLayout";
import {Header} from "./header/Header";
import {HeaderMobile} from "./header-mobile/HeaderMobile";
import {Aside} from "./aside/Aside";
import {Footer} from "./footer/Footer";
import {LayoutInit} from "./LayoutInit";
import {SubHeader} from "./subheader/SubHeader";
import { useSelector } from "react-redux";
import BackdropFilter from "./BackdropFilter";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import ShowTour from "./ShowTour";
import { ShowTourContext } from "../../../hooks/ContextStore";

export function Layout({ children }) {
    const {subscriptionData} = useSelector(state => state.subscriptionData);
    const [disabledDescription, setDisabledDiscription] = useState("");
    const [planModules, setPlanModules] = useState([]);
    const uiService = useHtmlClassService();
    const [showTour, setShowTour] = useContext(ShowTourContext);

    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);

    const location = useLocation();

    const handleFetchPlanModules = async () => {
        await Axios.get(`api/v2/app-modules/available_modules/${subscriptionData?.items?.[0]?.stripe_plan}`)
        .then(res => {
            setPlanModules(res.data || []);
        });
    };

    const handleFetchModuleDisabledDescription = async (module_slug) => {
        await Axios.get(`api/console/get_module/${module_slug}`)
        .then(res => {
            if(res.data.video_url !== ""){
                setDisabledDiscription(`${res.data.description} <br /> <video src=${res.data.video_url} autoplay controls style="height: 300px; width: 300px"></video>`);
            } else {
                setDisabledDiscription(res.data.description || "");
            }
        });
    };

    useEffect(() => {
        handleFetchPlanModules();
    }, []);

    const isDisabled = () => {
        if(planModules.length === 0){
            return true;
        }
        if(planModules.some(planModule => location.pathname.includes(planModule.slug))){
            let planModule = planModules.filter(planModule => location.pathname.includes(planModule.slug))?.[0];
            handleFetchModuleDisabledDescription(planModule.slug);
            return !Boolean(planModule.is_enabled);
        }
    };

    return layoutProps.selfLayout !== "blank" ? (
        <>
            {/*begin::Main*/}
            <HeaderMobile/>
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {layoutProps.asideDisplay && (<Aside/>)}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header/>
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                        >
                            {layoutProps.subheaderDisplay && <SubHeader/>}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    {/*begin::Container*/}
                                    {/* <div className={!isDocumentPage ? layoutProps.contentContainerClasses : 'container-fluid'}>
                                        {children}
                                    </div> */}
                                    <div className={'container-fluid content-container-pages22'}>
                                        {isDisabled() && (
                                            <BackdropFilter description={disabledDescription}/>
                                        )}
                                        {showTour && <ShowTour />}
                                        {children}
                                    </div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && {children}}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <Footer/>
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {/* <QuickUser/>
            <QuickPanel/>
            <ScrollTop/> */}
            {/* <StickyToolbar/> */}
            {/*end::Main*/}
            <LayoutInit />
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}
