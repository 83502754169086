import React, { useContext } from "react";
import { EstateContext } from "../../../../hooks/ContextStore";
import RealProperty from "./RealProperty/RealProperty";
import MineralIntests from "./RealProperty/components/MineralInterest/MineralInterest";

function RealEstate() {


  return (
    <>
      <div id="real-property" className={`card h-100 card-custom `}>
        <RealProperty /> 
      </div>
    </>
  );
}

export default RealEstate;
