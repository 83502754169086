
import MomentUtils from "@date-io/moment";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from 'react';
import * as yup from "yup";

const validationSchema = yup.object({ 
    address: yup.string().nullable(),
    city: yup.string().nullable(), 
    state: yup.string().nullable()
});

function CreateSpousePreviousResidences(props) {
    const { obj, setObj }                      = props;
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("client_case_id");  
    const [residedDate, setResidedDate]         = React.useState(new Date());

    const onSubmit = async (values) =>{ 
        values["client_case_id"]        = decypt_data;
        const formObj ={
            client_case_id: decypt_data ? decypt_data : null,
            address: values.address,
            city: values.city,
            state: values.state,
            resided_date: residedDate,
        };
        Axios.post(`api/spouse/previous/residence/`,formObj)
        .then((res)=>{
            setObj(obj.concat(res.data.data));
        })   
        // await createClientPrev(values);
        handleClose();
    }

    const handleResidedDate = (date) => {
        let date_dt = new Date(date);
        let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
        setResidedDate(new_date);
    };

    function handleClickOpen() {
        formik.resetForm();
        setOpen(true); 
    }

    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {
            address: "",
            city: "",
            state: "",
            resided_date: residedDate
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    return (
        <> 
            <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Create Spouse Previous Residences</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row">  
                        <div className="col-lg-12 mb-4">
                        <label>Address</label>
                            <TextField
                                placeholder="Address"
                                label="Address"
                                name="address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.address &&  formik.errors.address ? formik.errors.address:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>City</label>
                            <TextField
                                name="city"
                                placeholder="City"
                                label="City"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.city &&  formik.errors.city ? formik.errors.city:""}
                            </span>
                        </div> 
                        <div className="col-lg-12 mb-4 ">
                            <label>State</label>
                            <TextField
                                placeholder="Primary Teacher"
                                label="State"
                                name="state"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                                className="custom-border"
                                variant="outlined" 
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.state &&  formik.errors.state ? formik.errors.state:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4 ">
                            <label>Date Resided</label>
                            <MuiPickersUtilsProvider  utils={MomentUtils}>
                                <KeyboardDatePicker 
                                    disableToolbar
                                    variant="inline"
                                    format="MM/DD/yyyy"
                                    className="custom-border"
                                    id="date-picker-inline"
                                    value={residedDate}
                                    onChange={handleResidedDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.state &&  formik.errors.state ? formik.errors.state:""}
                            </span>
                        </div> 
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </div>
                </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateSpousePreviousResidences
