import { FormControl, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import React from 'react'

function TableCategory(props) {
    const { estate } = props;
    return (
        <>
            <div className="card-title mt-5">
                <div className="d-flex px-9 align-items-center">
                    <div className="d-flex flex-column">
                        <h6>{estate.name}</h6>     
                    </div> 
                </div>
            </div>
            <div className="pb-10 pl-10 pr-10 pt-0">
                <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                    <TableRow> 
                        <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                        Description
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Value
                        </TableCell> 
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Client SP
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Spouse SP
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Net Value
                        </TableCell> 
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow> 
                            <TableCell> 
                                <TextField 
                                    label="Description"
                                    value={"Static Description"}
                                    variant="outlined"/>
                            </TableCell> 
                            <TableCell> 
                                <TextField 
                                    label="Value"
                                    value={500}
                                    variant="outlined"/>
                            </TableCell>  
                            <TableCell> 
                                <TextField 
                                    label="Client SP"
                                    value={500}
                                    variant="outlined"/>
                            </TableCell>  
                            <TableCell> 
                                <TextField 
                                    label="Client SP"
                                    value={500}
                                    variant="outlined"/>
                            </TableCell>  
                            <TableCell> 
                                <TextField 
                                    label="Net Value"
                                    value={1000}
                                    disabled={true}
                                    variant="outlined"/>
                            </TableCell>  
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default TableCategory