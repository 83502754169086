import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Slide, TextField } from "@material-ui/core";
import { CheckCircleTwoTone } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { GetCurrentLawfirm, GetCurrentLawfirmOwner, GetUserId, GetUserRole } from "../../../../../Functions/General";
import { FormikSubmittingLoading } from "../../../../../Functions/HelperFunctions";
import { actions } from "../../../../modules/Auth/_redux/lawfirmRedux";
import { CreateSIBContact } from "../../../AdminDashboard/components/SendInBlueAPI";

const validationSchema = () => Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    case_name: Yup.string().required('Case Name is required'),
    email: Yup.string().email('Email is invalid').required("Email is required"),
    members: Yup.array().test(
        'please-select-lawyer', 
        'Please Assign a Team Member', 
        (value) => {
            return value.length >= 1;
        }),
    modules: Yup.object().test(
        'at-least-one-module',
        'At least one module must be selected',
        (value) => {
            return(
                Object.values(value).filter((v) => v === true).length >= 1
            );
        }
    )
    
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function AddProspectiveModal({setClients, clients, membership}){
    const [open, setOpen] = useState(false);
    const [lawyers, setLawyers] = useState([]);
    const owner = GetCurrentLawfirmOwner();
    const lawfirm = GetCurrentLawfirm();
    const userId = GetUserId();
    const dispatch = useDispatch();
    const role = GetUserRole();
    const { user } = useSelector(state => state.auth);
    const lawfirmMember = user.data;
    const [showAlert ,setShowAlert] = useState(false)
    const handleAddToSendInBlue = async (email) => {
        let config = await CreateSIBContact(email, [7]);
        await Axios.request(config);
    };

    const handleFetchClientsToState = () => {
        //Fetch Active Clients
        Axios.get(`api/details/client/${lawfirm.id}`)
        .then((res) => {
            let lawfirm_clients = res.data.data;
            let user_assigned_clients = lawfirm_clients.filter(client => client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId));
            dispatch(actions.SET_ACTIVE_CASES(user_assigned_clients));
        })
        //Fetch Prospective Clients
        Axios.get(`api/details/client/${lawfirm.id}/prospectivelist`)
        .then((res) => {
            let lawfirm_clients = res.data.data;
            let user_assigned_clients = lawfirm_clients.filter(client => client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId));
            dispatch(actions.SET_PROSPECTIVE_CASES(user_assigned_clients))
        })
    };

    const handleGetAllLawfirmLawyers = () => {
        Axios.get(`api/lawyer/list/${lawfirm.id}`)
        .then(res => {
            let lawyers = res.data.data;
            lawyers.push(owner);
            setLawyers(lawyers);
        })
        .catch((e)=> {
            console.log(e)
        })
    };

    const handleDefaultLawyers = () => {
        let ownerIndex = lawyers.length - 1;
        let userIndex  = lawyers.findIndex(lawyer => lawyer.id === userId);

        //If the current user is also not the owner.
        if(ownerIndex !== userIndex){
            return [
                lawyers[ownerIndex], //This the Owner. Refer to handleGetAllLawfirmLawyers function. You'll see that we push the owner.
                lawyers[userIndex] //This is also to set the current user. If user is also not the owner.
            ]
        }

        return [
            lawyers[ownerIndex], 
        ];
    };

    const handleFormikSubmit = (values) => {
        formik.setSubmitting(true);
        //Summize the assigned lawyers to their id's only.
        let assignedLawyers = values.members.map(lawyer => lawyer.id);
        values['members'] = assignedLawyers;

        Axios.post(`/api/client`, values)
        .then(res => {
            setOpen(false);
            // setClients([...clients, res.data.data]);
            handleFetchClientsToState();
            handleAddToSendInBlue(values.email);
            setShowAlert(true);
        })
        .catch((err) => {
            if(err.response && err.response.status === 422){
                for(const [key, value] of Object.entries(err.response.data.errors)){
                    formik.setFieldError(key, value[0]);
                }
            }
        })
        .finally(() => {
            formik.resetForm();
            formik.setSubmitting(false);
        })
    };

    const handleOpenModal = () => {
        setOpen(!open);
    };

    const formik = useFormik({
        initialValues:{
            lawfirm_id: lawfirm.id,
            created_by: userId,
            prospective_client: true,
            send_emails:'yes',
            status: 'prospective',
            assign_case_owner: 1,
            members:    role.toLocaleLowerCase() === 'lawyer' || 
                        role.toLocaleLowerCase() === 'member'  ? 
                        [lawfirmMember,owner] :
                        [owner],
            modules:{
                budget: false,
                children: false,
                estate: false,
                estate_division: false,
                timeline: false,
                notes: false,
                documents: false
            },
            with_child: 1,
            without_child: 1,
            suit_affecting: 1,
            modification_prior_orders: 1,
            other_type_case: 1
        },
        validationSchema: validationSchema,
        onSubmit: handleFormikSubmit
    });

    useEffect(()=>{
        handleGetAllLawfirmLawyers()
        return() => {
            handleGetAllLawfirmLawyers()
        }
    },[])
        //changes send_emails to yes or no
        const handleChangeSendEmails = (event) => {
            formik.setFieldValue('send_emails', event.target.checked ? 'yes' : 'no');
        };


        const handleCloseAlrt = () => {
            setShowAlert(false)
        }
    return(
        <>
        <Dialog
            open={showAlert}
            TransitionComponent={Transition}
            fullWidth={true}
            onClose={handleCloseAlrt}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                Success!
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                <div className={'d-flex justify-content-center'}>
                    <CheckCircleTwoTone style={{ fontSize: 100, color: '#3699ff' }} />
                </div>
                <div >
                Client portal created! 
                <br/>
                A client portal has been created for this client. Unless you opted out, they will now receive an email with a link to their portal. <strong>Please make sure to ask them to check their junk mail folder if they do not receive it in their inbox.</strong>
                <br/>
                If you do not see this client portal on your dashboard, please click "Clients" in the left side menu and find them either in the "Prospective Clients" or "Active Clients" list. Edit their file to add yourself as a member of their file. Once you do this, you should see their name on your dashboard.
                </div>
            </DialogContentText>
            </DialogContent>
            <DialogActions className={'d-flex justify-content-center'}>
            <Button variant="contained" className="text-white w-25 py-5" onClick={handleCloseAlrt} color="primary">
                Okay
            </Button>
            </DialogActions>
        </Dialog>
        <Button className="ml-3 btn custom-blue-button" disabled={membership?.status === 'expired'} onClick={handleOpenModal} color="primary">
            Create
        </Button>
        <Dialog open={open} onClose={handleOpenModal}>
            <DialogTitle>Create Prospective Client</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <div className="form-group row">
                        <div className="col-lg-6 mb-4">
                            <label>First Name</label>
                            <TextField
                                name="firstname"
                                placeholder="First Name"
                                label="First Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstname}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.firstname && formik.errors.firstname &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.firstname}
                                        </div>
                                    </div>
                                }
                            </span>
                        </div>
                        <div className="col-lg-6 mb-4 ">
                            <label>Last Name</label>
                            <TextField
                                placeholder="Last Name"
                                label="Last Name"
                                name="lastname"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastname}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.lastname && formik.errors.lastname &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.lastname}
                                        </div>
                                    </div>    
                                }
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Case Title</label>
                            <TextField
                                name="case_name"
                                placeholder="Case Title"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.case_name}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.case_name && formik.errors.case_name &&
                                    <div className="fv-plugins-message-container notif-warning">
                                    <div className="fv-help-block">
                                    Case Title is required!
                                    </div>
                                </div>
                                }
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>
                                Assign Team Members (Make sure YOUR name is selected.)
                            </label>
                            <Autocomplete
                                id="members"
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('members', newValue);
                                }}
                                multiple
                                options={lawyers}
                                getOptionLabel={(option) => option?.firstname + " " + option?.lastname}
                                defaultValue={handleDefaultLawyers}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={`${option?.firstname} ${option?.lastname} ${option?.id === owner?.id ? `(Owner)` : ''}`} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params)=>(
                                    <TextField
                                        {...params}
                                        variant="standard"
                                    />
                                )}
                            >
                            </Autocomplete>
                            {formik.errors.members &&
                                <div className="fv-plugins-message-container notif-warning">
                                    <div className="fv-help-block">
                                    {formik.errors.members}
                                    </div>
                                </div>    
                            }
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Client's Email :</label>
                            <TextField
                                placeholder="Client's Email"
                                label="Client's Email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.email && formik.errors.email &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.email}
                                        </div>
                                    </div>
                                }
                            </span>
                        </div>

                        <div className="col-lg-12 mb-2">
                            <FormControlLabel
                                control={
                                <Checkbox
                                checked={formik.values.send_emails === 'yes'}
                                onChange={handleChangeSendEmails}
                                    name="send_emails"
                                />
                                }
                                label="Send Email"
                            />
                        </div>

                        <FormControl
                            error={formik.touched.modules && Boolean(formik.errors.modules)}
                            className="col-lg-12 mb-2"
                            required
                            component="fieldset"
                            >
                            <FormLabel required={false}>Select Available Modules</FormLabel>
                            <FormGroup className="col-lg-12 mb-2">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.budget}
                                    onChange={formik.handleChange}
                                    name="modules.budget"
                                    />
                                }
                                label="Budget"
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.children}
                                    onChange={formik.handleChange}
                                    name="modules.children"
                                    />
                                }
                                label="Children"
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.estate}
                                    onChange={formik.handleChange}
                                    name="modules.estate"
                                    />
                                }
                                label="Estate"
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.timeline}
                                    onChange={formik.handleChange}
                                    name="modules.timeline"
                                    />
                                }
                                label="Timeline"
                                />
                            </FormGroup>
                                {formik.errors.modules &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.modules}
                                        </div>
                                    </div>    
                                }
                        </FormControl>

                    </div>
                </DialogContent>
                <DialogActions className="pt-5">
                    <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                    >
                    Submit
                    {FormikSubmittingLoading(formik)}
                    </Button>
                    <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    size="large"
                    color="secondary"
                    >
                    Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
        </>
    );
}