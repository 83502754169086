import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from 'react';
import * as yup from "yup";

const validationSchema = yup.object({ 
    full_name: yup.string().nullable(),
    age: yup.number().nullable().positive().integer(), 
    relation_children: yup.string().nullable()
});

function CreateOtherHouseHold(props) {
    const { 
        createHouseHold,
        title,
        subtitle
    }                               = props;
    const [open, setOpen]           = useState(false);
    const encryptStorage            = new EncryptStorage("secret-key");
    const decypt_data               = encryptStorage.getItem("client_case_id");  
  
    const onSubmit = async (values) => {
        
        values["client_case_id"] = decypt_data;
        await createHouseHold(values);
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {  
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    function handleClickOpen() {
        formik.resetForm()
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }


    return (
        <>
             <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
             <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    {title}
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    {subtitle} 
                    </span>
                </h3>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group row">  
                                    <div className="col-12">
                                        <label>Name</label> 
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            placeholder="Name"
                                            label="Name"
                                            name="full_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.full_name}
                                            margin="normal"
                                            variant="outlined"
                                            className="custom-border"
                                        />
                                        <br/>
                                        <span className='text-danger'>
                                        {formik.touched.full_name &&  formik.errors.full_name ? formik.errors.full_name:""}
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <div className="col-12">
                                        </div>
                                        <TextField
                                            placeholder="Age"
                                            label="Age"
                                            name="age"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.age}
                                            margin="normal"
                                            variant="outlined"
                                            type="number"
                                            className='custom-border'
                                        />
                                        <br/>
                                        <span className='text-danger'>
                                        {formik.touched.age &&  formik.errors.age ? formik.errors.age:""}
                                        </span>
                                    </div>  
                                <div className="col-lg-12 mb-4">
                                    <div className="col-12">
                                    </div>
                                    <TextField
                                        placeholder="Relationship"
                                        label="Relationship"
                                        name="relation_children"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.relation_children}
                                        margin="normal"
                                        variant="outlined"
                                        className='custom-border'
                                    />
                                    <br/>
                                    <span className='text-danger'>
                                    {formik.touched.relation_children &&  formik.errors.relation_children ? formik.errors.relation_children:""}
                                    </span>
                                </div>
                            </div>  
                            <DialogActions>
                                <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                    Cancel
                                </Button>
                                <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                                    Submit
                                </Button>
                            </DialogActions>
                    </form>
                </DialogContentText>
            </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateOtherHouseHold
