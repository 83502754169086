import { FormControl, IconButton, InputLabel, MenuItem, NativeSelect, Select, TextField } from '@material-ui/core' 
import { Add } from '@material-ui/icons';
import React, { useContext, useMemo, useState } from 'react' 
import { GetClientCaseId } from '../../../../Functions/General';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { FillContext } from '../../../../hooks/ContextStore'; 
import { 
    CreateAnimals,
    CreateBankAccount, 
    CreateBonus, 
    CreateBrokerage, 
    CreateBusiness, 
    CreateCashOnHand, 
    CreateChildren, 
    CreateClub, 
    CreateEquitableClaims, 
    CreateInsurance, 
    CreateLiabilities, 
    CreateMoney, 
    CreateMsc, 
    CreatePersonalProperty, 
    CreatePropertyHeld, 
    CreateRealProperty, 
    CreateRetirment, 
    CreateStocks,
    CreateTravelAward,
    CreateVehicle
} from '../Service/Request';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { Form } from 'react-bootstrap';
import { Label } from 'react-bootstrap';

function Create() {
    const client_case_id = GetClientCaseId(); 
    const [fill, setFill] = useContext(FillContext);
    const [date, setDate] = useState(new Date());

    const estateList = useMemo( () =>[
        {
            name: "Real Property",
            function: CreateRealProperty,
            condition: fill?.real_property || fill?.mineral_interest,
            link: "/estatev2/real-estate",
        },
        {
            name: "Cash On Hand",
            function: CreateCashOnHand,
            condition: fill?.cash || fill?.equivalents,
            link: "/estatev2/cash-onhand",
        },
        {
            name: "Bank Accounts",
            function: CreateBankAccount,
            condition: fill?.bank_account,
            link: "/estatev2/bank-accounts",
        },
        {
            name: "Brokerage",
            function: CreateBrokerage,
            condition: fill?.brokerage,
            link: "/estatev2/brokerage",
        },
        {
            name: "Stocks",
            function: CreateStocks,
            condition: fill?.public_stocks || fill?.employee_stock_option || fill?.employee_stock,
            link: "/estatev2/stocks",
        },
        {
            name: "Bonuses",
            function: CreateBonus,
            condition: fill?.bonuses,
            link: "/estatev2/bonuses",
        },
        {
            name: "Business Interests",
            function: CreateBusiness,
            condition: fill?.business_interest,
            link: "/estatev2/business-interests",
        },
        {
            name: "Retirement Benefits",
            function: CreateRetirment,
            condition:
                fill?.contribution_plan || fill?.benefit_plan || fill?.ira_sep || fill?.military_benefit || 
                fill?.non_qualified_plan || fill?.government_benefits || fill?.compensation_benefits || fill?.union_benefits,
            link: "/estatev2/retirement-benefits",
        },
        {
            name: "Insurance and Annuities",
            function: CreateInsurance,
            condition: fill?.life_insurance || fill?.annuities,
            link: "/estatev2/insurance-annuities",
        },
        {
            name: "Other Asset Types/Miscellaneous",
            function: CreateMsc,
            condition:
                fill?.intellectual_property || fill?.safe_deposit || fill?.storage_facilities || 
                fill?.contingent_assets || fill?.other_assets || fill?.precious_metals,
            link: "/estatev2/miscellaneous-property",
        },
        {
            name: "Vehicles",
            function: CreateVehicle,
            condition: fill?.vehicles,
            link: "/estatev2/vehicles",
        },
        {
            name: "Money Owed",
            function: CreateMoney,
            condition: fill?.money_owned,
            link: "/estatev2/money-owed",
        },
        {
            name: "Personal Property",
            function: CreatePersonalProperty,
            condition: fill?.client_possession || fill?.spouse_possession || fill?.spouse_both,
            link: "/estatev2/personal-property",
        },
        {
            name: "Animals",
            function: CreateAnimals,
            condition: fill?.pets || fill?.livestock,
            link: "/estatev2/livestock",
        },
        {
            name: "Club Memberships",
            function: CreateClub,
            condition: fill?.club_membership,
            link: "/estatev2/club-memberships",
        },
        {
            name: "Travel Award Benefits",
            function: CreateTravelAward,
            condition: fill?.travel_award_benefit,
            link: "/estatev2/travel",
        },
        {
            name: "Community Liabilities",
            function: CreateLiabilities,
            condition:
                fill?.credit_card || fill?.federal_liabilities || fill?.contingent_liabilities || 
                fill?.amount_owned_to_attorney_or_professionals || fill?.other_liabilities,
            link: "/estatev2/community",
        },
        {
            name: "Children's Assets",
            function: CreateChildren,
            condition: fill?.children_assets,
            link: "/estatev2/child-accounts",
        },
        {
            name: "Property Held in Trust",
            function: CreatePropertyHeld,
            condition: fill?.asset_held_for_either_party || fill?.asset_held_for_benefit,
            link: "/estatev2/property-trust",
        },
        {
            name: "Equitable Claims",
            function: CreateEquitableClaims,
            condition: fill?.equitable_for_community_estate || fill?.equitable_for_separate_estate,
            link: "/estatev2/equitable-claims",
        },
    ], [fill]); 
    

    const validationSchema = Yup.object({
        description: Yup.string().required('Description is required'),
        value: Yup.number().required('Value is required'),
        debt: Yup.number().required('Debt is required'),
        clientSP: Yup.string().required('Client SP is required'),
        spouseSP: Yup.string().required('Spouse SP is required'), 
    });

    const formik = useFormik({
        initialValues: {
            description: '',
            value: '',
            debt: '',
            clientSP: '',
            spouseSP: '',
            date: date,
            category: '/estatev2/real-estate',
            type: 'checking',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values['date'] = date;
            const selected = estateList.find(estate => estate.link === formik.values.category);
            if (selected) {
                selected.function(values, client_case_id);
            }
        },
    });

    const handleChange = (date) => {
        let date_dt = new Date(date);
        let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
        setDate(new_date);
      };

    return (
        <div className="card mt-5"> 
            <div className="card-body">
                <form onSubmit={formik.handleSubmit}> 
                    <div className="timeline row"> 
                        <div className='col-3'>
                            <label>Select category</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select" 
                                className='w-100 my-2'
                                name="category"
                                value={formik.values.category}
                                onChange={formik.handleChange}
                                error={formik.touched.category && formik.errors.category}
                                helperText={formik.touched.category && formik.errors.category}
                            > 
                                {
                                    estateList?.map((estate) => (
                                        <MenuItem key={estate.link} value={estate.link}>{estate.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="c row">
                        <div className='col-6'>
                            <TextField
                                className='w-100 my-2'
                                label="Description" 
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && formik.errors.description}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </div>
                        <div className='col-3'>
                            <TextField
                                className='w-100'
                                label="Value" 
                                name="value"
                                value={formik.values.value}
                                onChange={formik.handleChange}
                                error={formik.touched.value && formik.errors.value}
                                helperText={formik.touched.value && formik.errors.value}
                            />
                        </div>
                        <div className='col-3'>
                            <MuiPickersUtilsProvider   utils={MomentUtils}>
                                <KeyboardDatePicker
                                    className='w-100 my-2'
                                    disableToolbar
                                    variant="inline"
                                    format="MM/DD/yyyy"
                                    margin="normal"
                                    id="date-picker-inline" 
                                    value={date}
                                    name="date"
                                    onChange={handleChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    />
                            </MuiPickersUtilsProvider>
                        </div>
                        {
                            formik.values.category === '/estatev2/bank-accounts' && (
                                <div className='col-3'>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select" 
                                        className='w-100'
                                        name="type"
                                        value={formik.values.type}
                                        onChange={formik.handleChange}
                                        error={formik.touched.type && formik.errors.type}
                                        helperText={formik.touched.type && formik.errors.type}
                                    > 
                                    
                                        <MenuItem value="checking" selected>checking</MenuItem>
                                        <MenuItem value="savings">savings</MenuItem>
                                        <MenuItem value="money market">money market</MenuItem>
                                        <MenuItem value="certificate of deposit">certificate of deposit</MenuItem> 
                                    </Select> 
                                </div> 
                            )
                        }
                        <div className='col-3'>
                            <TextField
                                className='w-100 my-2'
                                label="Debt" 
                                name="debt"
                                value={formik.values.debt}
                                onChange={formik.handleChange}
                                error={formik.touched.debt && formik.errors.debt}
                                helperText={formik.touched.debt && formik.errors.debt}
                            />
                        </div>
                        <div className='col-3'>
                            <TextField
                                className='w-100 my-2'
                                label="Client SP" 
                                name="clientSP"
                                value={formik.values.clientSP}
                                onChange={formik.handleChange}
                                error={formik.touched.clientSP && formik.errors.clientSP}
                                helperText={formik.touched.clientSP && formik.errors.clientSP}
                            />
                        </div>
                        <div className='col-3'>
                            <TextField 
                                className='w-100 my-2'
                                label="Spouse SP" 
                                name="spouseSP"
                                value={formik.values.spouseSP}
                                onChange={formik.handleChange}
                                error={formik.touched.spouseSP && formik.errors.spouseSP}
                                helperText={formik.touched.spouseSP && formik.errors.spouseSP}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'> 
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                aria-label="delete"
                                size="large"
                                style={{ background: '#007bff', color: '#fff' }}
                                type='submit'
                            >
                                <Add fontSize="inherit" />
                            </IconButton>
                        </div>
                    </div> 
                </form>
            </div>
        </div>
    )
}

export default Create