/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import userflow from "userflow.js";
import { ShowTourContext } from "../../../../../hooks/ContextStore";
import { checkIsActive } from "../../../../_helpers";
import { getBreadcrumbsAndTitle } from "../../../_core/MetronicSubheader";

export function HeaderMenu({ layoutProps }) {
  const [showTour, setShowTour] = useContext(ShowTourContext);
  const user = useSelector(({ auth }) => auth.user.data);
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  userflow.init(process.env.REACT_APP_USER_FLOW_DEV_TOKEN);
  userflow.identify(user.id, {
    name: user.lastname,
    email: user.email,
    // signed_up_at:mome,
  });

  useEffect(()=>{
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    setPageTitle(aside.title);
    // const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    // subheader.setBreadcrumbs(breadcrumbs);
    // subheader.setTitle((aside && aside.title && aside.title.length > 0) ?  : header.title);
  },[location.pathname])

    // Lookup object for tour titles
    const tourTitles = {
      "Attorney Dashboard": "Dashboard Tour",
      "Dashboard": "Dashboard Tour",
      "Client Overview": "Client File Tour",
      "Timeline": "Timeline Tour",
      "Children": "Children's Tour",
      "Estate": "Estate Page Tour",
      "Budget": "Budget Tour",
      "Documents": "Documents Tour",
    };
  
    // Determine tour title efficiently
    const tourTitle = useMemo(() => {
      return (
        tourTitles[pageTitle] ||
        (location.pathname === "/estate-division"
          ? "Estate Division Tour"
          : location.pathname.includes("basics")
          ? "Basics Tour"
          : "")
      );
    }, [pageTitle, location.pathname]);

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/">
            <span className="" style={{ color: '#FFFFFF' }}>Home</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel `} >
          <button
            type="button"
            className="ml-3 btn custom-blue-button-dashboard"
            variant="contained"
            onClick={() => {
              userflow.start("6bb7b555-d21b-442c-9025-464afd173d49");
              setShowTour(true); // for now, just show the tour here
            }}
            color="primary"
          >
         {tourTitle}
          </button>
        </li>
        {/* <li className={`menu-item menu-item-rel `}>
            <button type="button" className="ml-3 btn custom-blue-button-dashboard" onClick={() => setShowTour(true)}>Show Tour</button>
        </li> */}
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
