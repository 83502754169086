import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  worst_accusation: yup.string().max(255, "Maximum Character reached"),
});

function CreateOrUpdateWorkAccusation(props) {
  const { createOrUpdate } = props;
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  const onSubmit = async (values) => {
    values["client_case_id"] = decypt_data;
    
    if(values.worst_accusation === ''){
      values.worst_accusation = 'N/A';
    }
    
    if (values.worst_accusation.length <= 255) {
      await createOrUpdate(values);
      handleClose();
    }
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const formik = useFormik({
    initialValues: {
      worst_accusation: props.workAccusation
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Bad Facts – Other Parent
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              If you have not done so already elsewhere in this section, please
              outline in detail any major concerns about the other parent.
            </span>
          </h3>
        </DialogTitle>
        <label className={"MuiDialogTitle-root"}>
          It is important to make sure your attorney is prepared, and is not
          surprised by bad facts during your case. For that reason it is
          important that you disclose all relevant facts in your case, even if
          some of those facts are difficult to disclose. Remember that no parent
          is perfect, and all parents have done things they regret.{" "}
        </label>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4">
                  <label>Bad facts about other parent</label>
                  <TextField
                    name="worst_accusation"
                    label="Bad facts about other parent"
                    placeholder="Work accusation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.worst_accusation}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                    className="custom-border"
                  />
                  <p>{`${formik.values.worst_accusation}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.worst_accusation &&
                    formik.errors.worst_accusation
                      ? formik.errors.worst_accusation
                      : ""}
                  </span>
                </div>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    autoFocus
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdateWorkAccusation;
