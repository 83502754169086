import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from '@material-ui/core';
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from 'react';
import * as yup from "yup";

const validationSchema = yup.object({  

});

function CreateOrUpdateChildPreference({createOrUpdate}) {
    const [open, setOpen]                           = useState(false);
    const [askingCutody, setAskingCutody]           = useState(); 
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");  
    
    const onSubmit = async (values) =>{
        values["client_case_id"]    = decypt_data; 
        values["child_prefrence"]   = askingCutody;
        await createOrUpdate(values); 
        handleClose(); 
    }

    const formik = useFormik( 
    {
        initialValues: {  
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    function handleClickOpen() {
        formik.resetForm();
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }
    return (
        <>
            <div className="card-toolbar">
                <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Child’s Preference</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row">
                        <div className="col-lg-12 mb-4 ">
                            <label>Child’s Preference?</label>
                            <RadioGroup aria-label="gender" name="gender1" defaultValue="yes" onChange={e=> setAskingCutody(e.target.value)}>
                                <FormControlLabel 
                                    value="yes" 
                                    control={<Radio />} 
                                    label="Yes" 
                                />
                                <FormControlLabel 
                                    value="no" 
                                    control={<Radio />} 
                                    label="No" 
                                /> 
                            </RadioGroup>
                            <br/>
                            <span>
                            {formik.touched.child_prefrence &&  formik.errors.child_prefrence ? formik.errors.child_prefrence:""}
                            </span>
                        </div>  
                        <div className="col-lg-12 mb-4 ">
                            <label>	Details of Preference</label>
                            <TextField
                                placeholder="Details of Preference"
                                label="Details of Preference"
                                name="detail_preference"  
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.detail_preference}
                                className="custom-border"
                                variant="outlined" 
                            /> 
                        </div>  
                        <div className="col-lg-12 mb-4 ">
                            <label>Changes in Preference</label>
                            <TextField
                                placeholder="Changes in Preference"
                                label="Changes in Preference"
                                name="changes_preference" 
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.changes_preference}
                                className="custom-border"
                                variant="outlined" 
                            /> 
                        </div> 
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button className="text-white" variant="contained" color="primary" size="large" type="submit" autoFocus>
                            Submit
                        </Button>
                      </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateOrUpdateChildPreference
