import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Create from './Components/Create' 
import TableCategory from './Components/TableCategory';
import NotifyAttorney from '../DocumentsV2/components/NotifyAttorney';
import EstateCards from './Components/EstateCards';

function EstateV2() {  
    return (
        <div id="expenseTracker" className="row" style={{ gap: '10px'}}> 
            <div className="w-100">
                <div className="col-12 p-6 bg-white border-0 d-flex mb-7 align-items-center shadow rounded">
                        <div className="">
                        <h3>Estate</h3>
                        {/* <EstateDropdown/> */}
                        </div>

                        <div className="ml-auto d-flex">
                        {/* <button
                            type="button"
                            className="btn btn-primary ml-auto"
                            onClick={handleClickOpen}
                        >
                            Categories
                        </button>
                        <EstateCategories
                            handleClickOpen={handleClickOpen}
                            open={open}
                            setOpen={setOpen}
                            handleClose={handleClose}
                        /> */}
                        <Link href="/estate-division">
                            <button type="button" className="ml-3 btn custom-blue-button">
                            Estate Division
                            </button>
                        </Link>
                        {/* <button
                            type="button"
                            className="ml-3 btn btn-primary d-none d-md-block"
                            disabled={downloading}
                            onClick={handleDownload}
                        >
                            Download Files{" "}
                            {downloading ? (
                            <span className="ml-3 spinner spinner-white p-3"></span>
                            ) : (
                            ""
                            )}
                        </button> */}
                        <div className="ml-3">
                            <NotifyAttorney />
                        </div>
                        </div>
                    </div>
                <Create />
            </div>
            <div className="card w-100"> 
                <EstateCards/>
            </div>
        </div> 
    )
}

export default EstateV2