/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute, Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ContextStore from "../hooks/ContextStore";
import ClientPage from "./ClientPage";
import DataStore from "../hooks/DataStore";
import GlobalComponents from "./global/GlobalComponents";
import LinaerStepper from "./modules/Payment/LinearStepper";
import SetNewPassword from "./modules/Auth/pages/SetNewPassword";
import ResetPassword from "./modules/Auth/pages/ResetPassword";
import Registration from "./modules/Registration/Registration";
export function Routes() {
  const { isAuthorized, verifiedMFA } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      verifiedMFA: auth.mfa
    }),
    shallowEqual
  );
  const { user } = useSelector((state) => state.auth);
  const role = user ? user.data.roles[0].name : null;

  return (
    <>
      <Switch>
        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />
        <ContentRoute path="/price/:priceKey" component={LinaerStepper} />
        <ContentRoute path="/price1/:priceKey" component={Registration} />
        <ContentRoute path="/auth/set-new-password/:code" component={SetNewPassword}/>
        <ContentRoute path="/resetpassword/:code" component={ResetPassword}/>
        {!isAuthorized ? (
        /* Render auth page when user is not authorized */
        <Route>
          <AuthPage />
          <Redirect to="/auth/login" />
        </Route>
      ) : (
        <>
          {isAuthorized && verifiedMFA && (
            <DataStore>
              <ContextStore>
                <Layout>
                  {role === "client" ? <ClientPage /> : <BasePage />}
                  <GlobalComponents />
                </Layout>
              </ContextStore>
            </DataStore>
          )}
          
          {isAuthorized && !verifiedMFA && (
              <Route>
                <AuthPage />
                <Redirect to="/auth/mfa" />
              </Route>
          )}
        </>
      )}
    </Switch>
    </>
  );
}
