import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { useFormik } from "formik";
import React, { useState } from 'react';
import * as yup from "yup";

const validationSchema = yup.object({ 
    disciplinary_method: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Caretaker name is required!")
});

function CreateOrUpdateDisciplinaryMethod (props) {
    const { createOrUpdate }                        = props;
    const [open, setOpen]                           = useState(false);
    const [generalReasoning, setGeneralReasoning]   = useState(); 
    
    const onSubmit = async () =>{
        if (generalReasoning.length <= 255){await createOrUpdate(generalReasoning);  
        handleClose(); }
    }

    const formik = useFormik( 
    {
        initialValues: {   
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    function handleClickOpen() {
        setGeneralReasoning(props.disciplinary)
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }

    return (
        <>
            <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Disciplinary Methods
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    How has discipline of the child(ren) been handled in the home? If there is more than one household, please explain how discipline has been handled in both.  
                    </span>
                </h3>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description"> 
                    <div className="form-group row"> 
                        <div className="col-lg-12 mb-4 ">
                            <TextField
                                placeholder="Disciplinary Method"
                                label="Disciplinary Method"
                                name="disciplinary_method"
                                multiline
                                onChange={e=> setGeneralReasoning(e.target.value)}
                                onBlur={formik.handleBlur}
                                value={generalReasoning}
                                className="custom-border"
                                variant="outlined"
                                rows={4}
                            />  <p>{`${generalReasoning}`.length}/255</p>
                        </div> 
                    </div>   
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={onSubmit} className="text-white" variant="contained" color="primary" size="large" type="submit" autoFocus>
                            Submit
                        </Button>
                    </DialogActions> 
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateOrUpdateDisciplinaryMethod
