import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  general_reasoning: yup.string(),
});

function CreateOrUpdateCustody({ ask, desc, createOrUpdate }) {
  const [open, setOpen] = useState(false);
  const [generalReasoning, setGeneralReasoning] = useState("");
  const [askingCutody, setAskingCutody] = useState("");

  const onSubmit = async (values, props) => {
    if (generalReasoning.length <= 255 && askingCutody.length <= 255) {
      await createOrUpdate(generalReasoning, askingCutody);
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  function handleClickOpen() {
    setGeneralReasoning(desc);
    setAskingCutody(ask === "N/A" ? "yes" : ask);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Custody Overview
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              An overview of the client’s desired outcome.
            </span>
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4 ">
                  <label>
                    What is your overall desired outcome for the parenting of
                    your child(ren)?
                  </label>
                  <TextField
                    defaultValue={askingCutody}
                    name="gender1"
                    multiline
                    value={askingCutody}
                    onChange={(e) => setAskingCutody(e.target.value)}
                    className="custom-border"
                    variant="outlined"
                    rows={4}
                  />
                  <p>{String(askingCutody).length}/255</p>
                  {/* <RadioGroup 
                                aria-label="gender" 
                                name="gender1"
                                defaultValue={askingCutody} 
                                value={askingCutody} 
                                onChange={e=> setAskingCutody(e.target.value)}
                            >
                                <FormControlLabel 
                                    value="yes" 
                                    control={<Radio />} 
                                    label="Yes" 
                                />
                                <FormControlLabel 
                                    value="no" 
                                    control={<Radio />} 
                                    label="No" 
                                />
                                <FormControlLabel 
                                    value="unsure" 
                                    control={<Radio />} 
                                    label="Unsure" 
                                />
                            </RadioGroup> */}
                  <br />
                  <span>
                    {formik.touched.name_of_order && formik.errors.name_of_order
                      ? formik.errors.name_of_order
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4 ">
                  <label>
                    Please provide your basic reasoning for your desired
                    outcome, and why you believe it would be in the best
                    interest of your children.
                  </label>
                  <TextField
                    placeholder="EXPLANATION"
                    label="Explanation"
                    defaultValue={generalReasoning}
                    name="general_reasoning"
                    multiline
                    value={generalReasoning}
                    onChange={(e) => setGeneralReasoning(e.target.value)}
                    className="custom-border"
                    variant="outlined"
                    rows={4}
                  />{" "}
                  <p>{String(generalReasoning).length}/255</p>
                </div>
              </div>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  className="text-white"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdateCustody;
