import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useFormik } from "formik";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { requestPassword } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then(() => {
          setShowDialog(true); // Show success dialog first
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  const handleCloseDialog = () => {
    setShowDialog(false);
    setIsRedirecting(true); // Trigger redirect after dialog is closed
  };

  if (isRedirecting) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <>
      {/* Success Dialog */}
      <Dialog open={showDialog} fullWidth onClose={handleCloseDialog}>
        <DialogTitle>Success!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="d-flex justify-content-center">
              <strong>Password reset email sent successfully.</strong>
            </div>
            <div>
              Please check your email, including your spam folder, for the reset link.
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button variant="contained" color="primary" onClick={handleCloseDialog}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <div className="d-flex flex-wrap">
        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 shadow cw-side-bar">
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <Link to="/" className="flex-column-auto m-5 pb-lg-0 pb-10 d-flex justify-content-center">
                <img alt="Logo" className="max-h-70px" src={toAbsoluteUrl("/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png")} />
              </Link>
            </div>
          </div>
        </div>
        <div className="login-form login-forgot h-100 d-flex justify-content-center align-items-center cw-content">
          <div>
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Forgotten Password?</h3>
              <div className="text-muted font-weight-bold">
                Enter your email to reset your password
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="form">
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-solid h-auto py-5 px-6"
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                <button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" disabled={formik.isSubmitting}>
                  Submit
                </button>
                <Link to="/auth">
                  <button type="button" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
