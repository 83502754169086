import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputBase,
    MenuItem,
    Select,
    TextField,
    withStyles
} from '@material-ui/core';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import * as yup from "yup";

const validationSchema = yup.object({ 
    description: yup.string().nullable(),
    parental_involvement: yup.string().nullable().max(255, "Maximum Character reached"),
});

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

function CreateActivities(props) {
    const { createActiviy }                      = props;
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("client_case_id");  
    const [children, setChildren]               = useState([]);
    const [selectedChild, setSelectedChild]     = useState();
    const [defaultVal, setDefaultVal]           = useState();

    useEffect(() => { 
        Axios.get(`api/children/involve/list/${decypt_data}`)
        .then((res) => {
            setDefaultVal(res.data.data.length === 0 ? 0 : res.data.data[0].id)
            setChildren(res.data.data); 
        });
    }, [decypt_data]);

    const onSubmit = async (values,props) =>{ 
        values["client_case_id"]        = decypt_data;
        values["children_id"]           = selectedChild;
    
        await createActiviy(values);
        handleClose();
    }

    function handleClickOpen() {
        formik.resetForm()
        setOpen(true); 
        setSelectedChild(defaultVal);
    }

    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {
            description: "",
            parental_involvement: "" 
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    return (
        <> 
            <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Extracurricular Activities
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Please explain the nature of activity and any parental involvement.
                    </span>
                </h3>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row"> 
                        <div className="col-lg-12 mb-4">
                            <label>Children's Name</label>   
                            <Select
                                input={<BootstrapInput />}  
                                label="Age" 
                                className={'w-100'}
                                defaultValue={defaultVal}
                                onChange={e=>setSelectedChild(e.target.value)}
                            > 
                            {
                                children && children.map((child)=> ( 
                                    <MenuItem value={child.id}>{child.firstname} {child.lastname}</MenuItem>
                                ))
                            } 
                            </Select> 
                        </div>
                        <div className="col-lg-12 mb-4">
                        <label>Extra Curricular Activity</label>
                            <TextField
                                placeholder="Extra Curricular Activity"
                                label="Extra Curricular Activity"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br/>
                            <span>
                            {formik.touched.description &&  formik.errors.description ? formik.errors.description:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Parental Involvement</label>
                            <TextField
                                name="parental_involvement"
                                placeholder="Parental Involvement"
                                label="Parental Involvement"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.parental_involvement}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br/><p>{formik.values.parental_involvement.length}/255</p>
                            <span className="text-danger">
                            {formik.touched.parental_involvement &&  formik.errors.parental_involvement ? formik.errors.parental_involvement:""}
                            </span>
                        </div> 
                      </div>   
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                            Submit
                        </Button>
                      </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateActivities
