
import { Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useContext, useEffect, useMemo, useState } from 'react';
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { EstateContext, FillContext } from '../../../../hooks/ContextStore';
import BankAccount from '../_partials/BankAccount';
import Bonuses from '../_partials/Bonuses';
import Brokerage from '../_partials/Brokerage';
import Business from '../_partials/BusinessInterest';
import CashOnhand from '../_partials/CashHand';
import ChildAssets from '../_partials/ChildAssets';
import ClubMemberships from '../_partials/ClubMemberships';
import CommunityLiabilities from '../_partials/CommunityLiabilities';
import EquitableClaims from '../_partials/EquitableClaim';
import Insurance from '../_partials/InsuranceAnnuities';
import Livestock from '../_partials/Livestock';
import Miscellaneous from '../_partials/Miscellaneous';
import MoneyOwed from '../_partials/MoneyOwed';
import Vehicle from '../_partials/MotorVehicles';
import PersonalProperty from '../_partials/PersonalProperty';
import PropertyHeld from '../_partials/PropertyHeld';
import RealProperty from '../_partials/RealEstate';
import Retirement from '../_partials/RetirementBenefits';
import Stocks from '../_partials/Stocks';
import TravelAwardBenefits from '../_partials/TravelAwardBenefits';

function EstateCards() {
    const [fill,setFill] = useContext(FillContext);
    const [expanded, setExpanded] = useState(null);
    const history = useHistory();
    const components = useMemo(() => [
        { Component: RealProperty, name: 'Real Property', condition: fill?.real_property || fill?.mineral_interest, link: "/estatev2/real-estate", icon: "/media/svg/icons/Home/Home.svg", subtitle: "Real property, including land & mineral rights" },
        { Component: CashOnhand, name: 'Cash On Hand', condition: fill?.cash || fill?.equivalents, link: "/estatev2/cash-onhand", icon: "/media/svg/icons/Shopping/Wallet.svg", subtitle: "Cash, cashier’s checks, traveler’s checks, etc." },
        { Component: BankAccount, name: 'Bank Accounts', condition: fill?.bank_account, link: "/estatev2/bank-accounts", icon: "/media/svg/icons/Shopping/Safe.svg", subtitle: "Checking, savings, & other deposit accounts" },
        { Component: Brokerage, name: 'Brokerage', condition: fill?.brokerage, link: "/estatev2/brokerage", icon: "/media/svg/icons/Shopping/Chart-line1.svg", subtitle: "Brokerage accounts & mutual funds" },
        { Component: Stocks, name: 'Stocks', condition: fill?.public_stocks || fill?.employee_stock_option || fill?.employee_stock, link: "/estatev2/stocks", icon: "/media/svg/icons/Communication/Thumbtack.svg", subtitle: "Stocks, bonds, and other securities, including employee stock options and restricted stock." },
        { Component: Bonuses, name: 'Bonuses', condition: fill?.bonuses, link: "/estatev2/bonuses", icon: "/media/svg/icons/Shopping/Money.svg", subtitle: "Bonuses expected by either spouse due to their work for an entity" },
        { Component: Business, name: 'Business Interests', condition: fill?.business_interest, link: "/estatev2/business-interests", icon: "/media/svg/icons/Map/Marker1.svg", subtitle: "Business interests of either spouse" },
        { Component: Retirement, name: 'Retirement Benefits', condition: fill?.contribution_plan || fill?.benefit_plan || fill?.ira_sep || fill?.military_benefit || fill?.non_qualified_plan || fill?.government_benefits || fill?.compensation_benefits || fill?.union_benefits, link: "/estatev2/retirement-benefits", icon: "/media/svg/icons/Shopping/Sort3.svg", subtitle: "401(k), IRA, pensions & other benefits" },
        { Component: Insurance, name: 'Insurance and Annuities', condition: fill?.life_insurance || fill?.annuities, link: "/estatev2/insurance-annuities", icon: "/media/svg/icons/Weather/Umbrella.svg", subtitle: "Life insurance policies & annuities" },
        { Component: Miscellaneous, name: 'Other Asset Types/Miscellaneous', condition: fill?.intellectual_property || fill?.safe_deposit || fill?.storage_facilities || fill?.contingent_assets || fill?.other_assets || fill?.precious_metals, link: "/estatev2/miscellaneous-property", icon: "/media/svg/icons/Home/Key.svg", subtitle: "Intellectual property, precious metals, safe deposit, storage, & other assets." },
        { Component: Vehicle, name: 'Vehicles', condition: fill?.vehicles, link: "/estatev2/vehicles", icon: "/media/svg/icons/Home/Key.svg", subtitle: "Cars, Watercraft, Aircraft, Cycles, Recreational Vehicles, etc." },
        { Component: MoneyOwed, name: 'Money Owed', condition: fill?.money_owned, link: "/estatev2/money-owed", icon: "/media/svg/icons/Shopping/Wallet3.svg", subtitle: "Amounts owed to either spouse from any borrower or debtor" },
        { Component: PersonalProperty, name: 'Personal Property', condition: fill?.client_possession || fill?.spouse_possession || fill?.spouse_both, link: "/estatev2/personal-property", icon: "/media/svg/icons/Files/User-folder.svg", subtitle: "Household items, furniture, appliances, electronics, sporting goods, jewelry, artwork, etc." },
        { Component: Livestock, name: 'Animals', condition: fill?.pets || fill?.livestock, link: "/estatev2/livestock", icon: "/media/svg/icons/Food/Chicken.svg", subtitle: "Pets & Livestock" },
        { Component: ClubMemberships, name: 'Club Memberships', condition: fill?.club_membership, link: "/estatev2/club-memberships", icon: "/media/svg/icons/General/User.svg", subtitle: "Club Memberships" },
        { Component: TravelAwardBenefits, name: 'Travel Award Benefits', condition: fill?.travel_award_benefit, link: "/estatev2/travel", icon: "/media/svg/icons/Home/Key.svg", subtitle: "Miles & points for frequent flyers, travelers, credit card use, etc." },
        { Component: CommunityLiabilities, name: 'Community Liabilities', condition: fill?.credit_card || fill?.federal_liabilities || fill?.contingent_liabilities || fill?.amount_owned_to_attorney_or_professionals || fill?.other_liabilities, link: "/estatev2/community", icon: "/media/svg/icons/Shopping/Credit-card.svg", subtitle: "Revolving debt, taxes owed, and other liabilities Marital/Community Liabilities" },
        { Component: ChildAssets, name: "Children's Assets", condition: fill?.children_assets, link: "/estatev2/child-accounts", icon: "/media/svg/icons/Communication/Group.svg", subtitle: "Accounts or other property held for the benefit of the children" },
        { Component: PropertyHeld, name: 'Property Held in Trust', condition: fill?.asset_held_for_either_party || fill?.asset_held_for_benefit, link: "/estatev2/property-trust", icon: "/media/svg/icons/General/Attachment1.svg", subtitle: "Property held by one spouse in trust for another person" },
        { Component: EquitableClaims, name: 'Equitable Claims', condition: fill?.equitable_for_community_estate || fill?.equitable_for_separate_estate, link: "/estatev2/equitable-claims", icon: "/media/svg/icons/Code/Warning-1-circle.svg", subtitle: "Claims of one estate against another" },
    ], [fill]);
     
    const handleChange = (name) => (event, isExpanded) => {
        setExpanded(isExpanded ? name : null);
        history.push(components.find(({ name: n }) => n === name).link);
    };
    //open the component based on the url
    useEffect(() => {
        const currentComponent = components.find(({ link }) => history.location.pathname.includes(link));
        if (currentComponent) {
            setExpanded(currentComponent.name);
        }
    }, [history.location.pathname,]);

    return (
        <div className="estate-page-holder">
            {components.map(({ Component, name, icon, condition, subtitle }, index) => (

                <Accordion
                    key={index}
                    expanded={expanded === name}
                    onChange={handleChange(name)}

                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon color={condition ? "secondary" : "action"} />}
                    >
                        <div className="d-flex flex-column align-items-start">
                            <span
                                className={`navi-text font-size-lg svg-icon svg-icon-md ${condition ? 'svg-icon-green' : ''} align-items-center`}
                                style={{ color: condition ? "#0e4dc5" : "black" }}
                            >
                                <SVG src={icon} style={{ width: 24, height: 24, marginRight: 8, pointerEvents: 'none' }} />
                                {/* if ever we need to add a badge to the icon put it here*/}
                                {name}
                            </span>
                            {expanded === name && (
                                <span className="text-muted font-weight-bold font-size-sm mt-1 ml-8">
                                    {subtitle}
                                </span>
                            )}
                        </div>
                    </AccordionSummary>
                    {expanded === name && <Component />}
                </Accordion>
            ))}
        </div>

    );
}
export default EstateCards;

