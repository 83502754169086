import Axios from "axios";

export const CreateRealProperty = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        street_address: values.description,
        value_date: values.date,
        values: {
            street_address: values.description, 
            value:values.value,
            debt: values.debt,
            client_sp: values.clientSP,
            spouse_sp: values.spouseSP, 
            mortgage: 0,
            other_liens: 0,
        }
    }; 
    return await Axios
        .post("api/estate/real/property", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        });

};

export const CreateCashOnHand = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        description: values.description,
        value:values.value,
        debt: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP,
        type: 1,
    };  
    return await Axios
        .post("api/estate/cash/onhand/cashes", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        });

};

export const CreateBankAccount = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        account_number: values.description,
        balance:values.value,
        debt: values.debt,
        balance_date: values.date,
        type: values.type,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/bank/account", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        });

};

export const CreateBrokerage = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        firm: values.description,
        value:values.value,
        debt: values.debt,
        value_date: values.date,
        value_date_2: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/brokerage", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateStocks = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        name: values.description,
        value:values.value,
        debt: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/stocks/public", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateBonus = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        name: values.description,
        value:values.value,
        debt: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/bonuses", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateBusiness = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        name: values.description,
        value:values.value,
        debt: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/closely/held/business", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};
export const CreateRetirment = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        name: values.description,
        value:values.value,
        debt: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/retirement/benefits/contribution", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};
export const CreateInsurance = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        name: values.description,
        value:values.value,
        value_date: values.date,
        other_liens: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/insurance", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateMsc = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        description: values.description,
        value:values.value,
        other_liens: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/insurance", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateVehicle = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        make: values.description,
        value:values.value,
        other_liens: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/retirement/benefits/vehicle", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateMoney = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        name: values.description,
        value:values.value,
        value_date: values.date,
        other_liens: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/retirement/benefits/money", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreatePersonalProperty = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        description: values.description,
        value:values.value,
        type:1,
        ownership: 'client',
        other_liens: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/asset/assets", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateAnimals = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        description: values.description,
        value:values.value,
        type:4,
        ownership: 'client',
        other_liens: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/asset/assets", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateClub = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        club: values.description,
        value:values.value, 
        other_liens: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/membership", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateTravelAward = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        name: values.description,
        value:values.value, 
        value_date: values.date,
        other_liens: values.debt,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/award", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateLiabilities = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        names: values.description,
        value:values.value, 
        other_liens: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/liabilities/credit/card", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateChildren = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        account_name: values.description,
        value:values.value, 
        other_liens: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP
    };  
    return await Axios
        .post("api/estate/assets/children", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};
export const CreatePropertyHeld = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        description: values.description,
        value:values.value, 
        other_liens: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP,
        type: 1
    };  
    return await Axios
        .post("api/estate/properties/held", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};

export const CreateEquitableClaims = async (values, client_case_id) => {
    const formObj = {
        client_case_id: client_case_id ? client_case_id : null,
        description: values.description,
        value:values.value, 
        other_liens: values.debt,
        value_date: values.date,
        client_sp: values.clientSP,
        spouse_sp: values.spouseSP,
        type: 1
    };  
    return await Axios
        .post("api/estate/equitable/claim", formObj)
        .catch((err) => {
            if (err && err.response) console.log("Error", err);
        }); 
};



