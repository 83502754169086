import React from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";

export default function BillingElements({formik}){
    const stripe = useStripe();
    const elements = useElements();
    
    const handleChange = async (event) => {
        if(event.complete){
            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement)
            });
            if(!error && typeof paymentMethod === 'object'){
                formik.setFieldValue('card_token', paymentMethod.id);
            }
        }
    };
    
    return(
        <>
        <div className="d-flex flex-column align-self-left col-12 px-0">
            <label htmlFor="company" className="font-weight-bolder">Card Number</label>
            <div className="form-control h-auto" style={{padding: '24px'}}>
                <CardNumberElement onChange={(e) => handleChange(e)}/>
            </div>
        </div>
        <div className="d-flex flex-row col-12 px-0">
            <div className="col-6 pl-0">
                <div className="d-flex flex-column">
                    <label htmlFor="company" className="font-weight-bolder">Expiry</label>
                    <div className="form-control h-auto" style={{padding: '24px'}}>
                        <CardExpiryElement onChange={(e) => handleChange(e)}/>
                    </div>
                </div>
            </div>
            <div className="col-6 pr-0">
                <div className="d-flex flex-column">
                    <label htmlFor="company" className="font-weight-bolder">CVC</label>
                    <div className="form-control h-auto" style={{padding: '24px'}}>
                        <CardCvcElement onChange={(e) => handleChange(e)}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};