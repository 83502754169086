import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from "react";

function CreateOrUpdatePlanChildCare(props) {
  const { createOrUpdate, plansChildCare, otherPlans } = props;
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  const onSubmit = async (values) => {
    values["client_case_id"] = decypt_data;
    await createOrUpdate(values);
    handleClose();
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const formik = useFormik({
    initialValues: {
      plan_child_care: plansChildCare,
      other_plan_child_care: otherPlans,
    },
    validateOnBlur: true,
    onSubmit,
  });

  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Plans for Child Care
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Please outline your future plans for child care when you are
              working, as well as the other parent’s plans, if you know what
              they are.
            </span>
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4">
                  <label>Plans for Child Care</label>
                  <TextField
                    name="plan_child_care"
                    label="Plans for Child Care"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.plan_child_care}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                    className="custom-border"
                  />
                  <p>{`${formik.values.plan_child_care}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.plan_child_care &&
                    formik.errors.plan_child_care
                      ? formik.errors.plan_child_care
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>Other Party's Plans for Child Care</label>
                  <TextField
                    name="other_plan_child_care"
                    label="Other Party's Plans for Child Care"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.other_plan_child_care}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                    className="custom-border"
                  />
                  <p>{`${formik.values.other_plan_child_care}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.other_plan_child_care &&
                    formik.errors.other_plan_child_care
                      ? formik.errors.other_plan_child_care
                      : ""}
                  </span>
                </div>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    autoFocus
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdatePlanChildCare;
