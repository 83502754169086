import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputBase,
    MenuItem,
    Select,
    TextField,
    createStyles,
    withStyles
} from '@material-ui/core';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import * as yup from "yup";

const validationSchema = yup.object({ 
    school_name: yup.string(),
    grade_attended: yup.string(), 
    primary_teacher: yup.string(),
    school_counselor: yup.string(),
});

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

function CreateSchool(props) {
    const { createSchool }                      = props;
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("client_case_id");  
    const [children, setChildren]               = useState([]);
    const [selectedChild, setSelectedChild]     = useState();
    const [defaultVal, setDefaultVal]           = useState();
    useEffect(() => { 
        Axios.get(`api/children/involve/list/${decypt_data}`)
        .then((res) => {
            setDefaultVal(res.data.data.length === 0 ? 0 : res.data.data[0].id);
            setChildren(res.data.data); 
        });
    }, [decypt_data]);

    const onSubmit = async (values) =>{ 
        values["client_case_id"]        = decypt_data;    
        values["children_id"]           = selectedChild;
        await createSchool(values);
        handleClose();
    }

    function handleClickOpen() {
        setOpen(true);  
        setSelectedChild(defaultVal);
        formik.resetForm();
    }

    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {
            school_name: "",
            grade_attended: "",
            primary_teacher: "",
            school_counselor: ""
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    return (
        <> 
            <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Basic School Information</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row"> 
                        <div className="col-lg-12 mb-4">
                            <label>Child's Name</label>   
                            <Select
                                input={<BootstrapInput />}  
                                label="Age" 
                                className={'w-100'}
                                defaultValue={defaultVal}
                                onChange={e=>setSelectedChild(e.target.value)}
                            > 
                            {
                                children && children.map((child)=> ( 
                                    <MenuItem value={child.id}>{child.firstname} {child.lastname}</MenuItem>
                                ))
                            } 
                            </Select> 
                        </div>
                        <div className="col-lg-12 mb-4">
                        <label>School Name</label>
                            <TextField
                                placeholder="School Name"
                                label="School Name"
                                name="school_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.school_name}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.school_name &&  formik.errors.school_name ? formik.errors.school_name:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Grade Attended</label>
                            <TextField
                                name="grade_attended"
                                placeholder="Grade Attended"
                                label="Grade Attended"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.grade_attended}
                                className="custom-border"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.grade_attended &&  formik.errors.grade_attended ? formik.errors.grade_attended:""}
                            </span>
                        </div> 
                        <div className="col-lg-12 mb-4 ">
                            <label>Primary Teacher</label>
                            <TextField
                                placeholder="Primary Teacher"
                                label="Primary Teacher"
                                name="primary_teacher"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.primary_teacher}
                                className="custom-border"
                                variant="outlined" 
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.primary_teacher &&  formik.errors.primary_teacher ? formik.errors.primary_teacher:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4 ">
                            <label>School counselor</label>
                            <TextField
                                placeholder="School Counselor"
                                label="School Counselor"
                                name="school_counselor"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.school_counselor}
                                className="custom-border"
                                variant="outlined" 
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.school_counselor &&  formik.errors.school_counselor ? formik.errors.school_counselor:""}
                            </span>
                        </div>
                      </div>  
                    
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                            Submit
                        </Button>
                      </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateSchool
