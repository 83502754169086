import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from "react";
import * as yup from "yup";
import { GetCurrentLawfirm, PageIsAuthorize } from "../../../../Functions/General";
import Alert from '../../../../_metronic/layout/components/customize/Alert';
const validationSchema = yup.object({
    email: yup.string() 
    .email("Please enter a valid email!") 
    .required("Email is required!")

});

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    statusBadge: {
      borderRadius: '6px',
      fontFamily: 'Poppins',
      fontWeight: '500',
      width: '90px',
      display: 'block',
      textAlign: 'center',
      fontSize: '12px',
      padding: '5px'
    },
    isDone: {
      background : '#C9F7F5',
      color: '#1BC5BD'
    },
    isPending: {
      background : '#E1F0FF',
      color: '#3699FF'
    },
    fullWidth: {
      width : '100%'
    },buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadingOverLey: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '999',
        background: '#8c8c8c3d'
    }
  });

function InviteLawyer() { 
    const [dialogOpen, setDialogOpen]                   = useState(false);
    const [loading, setLoading]                         = useState(false);
    const classes                                       = useRowStyles();
    const [response, setResponse]                       = useState({});
    const [showAlert, setShowAlert]                     = useState(false);
    const lawfirmId                                     = GetCurrentLawfirm();

    const onSubmit = async (values)=> {
        values["lawfirm_id"] = lawfirmId.id;
        setLoading(true)
        await axios.post('api/lawfirm/invite', values)
          .then((data)=> {
              setResponse (
                {
                  message: data.data.message,
                  status: 'success'
                }
              );

          })
          .catch((error)=>{
            let responseError       = error.response.data.errors;
            let responseKey         = Object.keys(responseError)
            let responseMessage     = '';

            responseKey.map((key)=> {
              responseError[key].map((value)=> {
                responseMessage += value + `\n`;
        
              });
            });
            setResponse(
              {
                message: responseMessage,
                status: 'danger'
              }
            );
            
          })
          .finally(()=> {
              setLoading(false)
              setShowAlert(true);
              closeDialog();
          })
    }

    const openAlert = ()=> {
        setShowAlert(true);
    }

    const closeAlert = ()=> {
        setShowAlert(false);
    }

    const formik = useFormik({
        initialValues: { 
          email: ""
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    const openDialog = ()=> {
        setDialogOpen(true);

    }
    
    const closeDialog =()=>{
        setDialogOpen(false);

    }

    return (
        <div>
          {
            PageIsAuthorize('invite-lawyer') === true ?   
            <button
                type="button"
                className="btn btn-primary"
                onClick={openDialog}
                title={"Add a user who's email address is already associated with a Clientwise account."}
            >
               Add Existing User
            </button> : null
          }
            <Alert showAlert={showAlert} closeAlert={closeAlert} message={response.message} status={response.status}/>
            <Dialog
            open={dialogOpen}
            maxWidth={'sm'}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
              {loading && 
                    <div className={classes.loadingOverLey}>
                        <CircularProgress size={90} className={classes.progress} color="secondary" />

                    </div>
              }
            
            <DialogTitle id="alert-dialog-title">{"Invite Team Member"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group row">
                  <div className="col-md-12 mb-4">
                      <label>Email</label>
                      <TextField
                        placeholder="Email"
                        label="Email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className="custom-border"
                        variant="outlined"
                      />
                      <br/>
                      <span>
                        {formik.touched.email &&  formik.errors.email ? formik.errors.email:""}
                      </span> 
                  </div>
                </div> 
                  <DialogActions>
                    <Button onClick={closeDialog} disabled={loading} color="primary">
                        Disagree
                    </Button>
                    <Button variant="outlined" size="large" type="submit" autoFocus>
                        submit
                    </Button>
                  
                </DialogActions>
            </form>
            </DialogContentText>
            </DialogContent>       
          </Dialog>
        </div>
    )
}

export default InviteLawyer
