import React from "react";

export default function BackdropFilter({ description }) {
    const defaultMessage = `
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
            <h1 class="text-center w-100">This Module is not Available. Please Subscribe.</h1>
            <a type="button" target="_blank" rel="noopener noreferrer" href="https://clientwiseportal.com/pricing/" class="btn btn-success mt-3">
                Subscribe Here
            </a>
        </div>
    `;

    const match = description?.match(/^(.*?)<br\s*\/?>(.*)$/);
    const videoUrlNull = description?.includes('<video src=null');
    const isMatchEmpty = !match || !match[1].trim() ||match[1].trim().toLowerCase() === "null";
    
    const isDescriptionEmpty = videoUrlNull && isMatchEmpty;

    return (
        <div className="d-flex flex-column align-items-center justify-content-center" 
             style={{ height: '100vh', width: '100%', left: '0', top: '0', zIndex: '1', position: 'fixed', backdropFilter: 'blur(5px)' }}>
            <div className="card shadow-lg text-white" 
                 style={{ minHeight: '300px', minWidth: '300px', background: '#3699ff' }}>
                <div className="card-body" style={{ minHeight: '200px' }}
                    dangerouslySetInnerHTML={{
                        __html: isDescriptionEmpty ? defaultMessage : description
                    }}>
                </div>
            </div>
        </div>
    );
};
