import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  work_travel: yup.string().max(255, "Maximum Character reached"),
  planning_to_move: yup.string().max(255, "Maximum Character reached"),
});

function CreateOrUpdatePlanningToMove(props) {
  const { createOrUpdate } = props;
  const [open, setOpen] = useState(false);
  const [plantomove, setPlantoMove] = useState();
  const [destination, setDestination] = useState();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  const onSubmit = async (values) => {
    values["client_case_id"] = decypt_data;
    values["planning_to_move"] = plantomove !== '' ? plantomove : 'N/A';
    values["destination"] = destination !== '' ? destination : 'N/A';
    
    if (plantomove.length <= 255 && destination.length <= 255) {
      await createOrUpdate(values);
      handleClose();
    }
  };

  function handleClickOpen() {
    setPlantoMove(props.toMove);
    setDestination(props.destination);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Plans to Move
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              If either you or your spouse plan to move in the near future,
              please outline those plans below.
            </span>
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4">
                  <label>Client or Other Parent Planning to Move?</label>
                  <TextField
                    name="destination"
                    label="Client or Other Parent Planning to Move"
                    onChange={(e) => setDestination(e.target.value)}
                    onBlur={formik.handleBlur}
                    value={destination}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                    className="custom-border"
                  />
                  <p>{`${destination}`.length}/255</p>
                  <br />
                  <span>
                    {formik.touched.destination && formik.errors.destination
                      ? formik.errors.destination
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>Details of plan to move</label>
                  <TextField
                    name="planning_to_move"
                    label="Details of Plan to Move"
                    onChange={(e) => setPlantoMove(e.target.value)}
                    onBlur={formik.handleBlur}
                    value={plantomove}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                    className="custom-border"
                  />
                  <p>{`${plantomove}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.planning_to_move &&
                    formik.errors.planning_to_move
                      ? formik.errors.planning_to_move
                      : ""}
                  </span>
                </div>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    autoFocus
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdatePlanningToMove;
