import {
  Divider,
  FormControl,
  TextField,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { default as Axios, default as axios } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BudgetContext } from "../../../../../../hooks/ContextStore";

export default function CreateFinancial(props) {
  const [setStats] = useContext(BudgetContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { payment, handleClickOpen, handleClose, open, client_case_id,handleCloseModal } = props;
  
  
  const expense = payment
    ? payment.filter(
        (pay) => pay.title === "Expenses related to investment property"
      )
    : 0;
  const comissions = payment
    ? payment.filter((pay) => pay.title === "Commissions")
    : 0;
  const attorney = payment
    ? payment.filter((pay) => pay.title === "Attorney’s Fees")
    : 0;
  const accountant = payment
    ? payment.filter((pay) => pay.title === "Accountant’s Fees")
    : 0;
  const others = payment
    ? payment.filter((pay) => pay.title === "Other professional fees")
    : 0;
  const savings = payment
    ? payment.filter((pay) => pay.title === "Savings Reserve")
    : 0;
  const disable = props.disable;
  const [description, setDescription] = useState(null);

  const { register, formState, getValues } = useForm({
    mode: "onBlur",
  });


  const handleSubmit = () =>{
    formChangedExpense();
    formChangedCommission();
    formChangedAttorney();
    formChangedAccountant();
    formChangedProffesional();
    formChangedSavings();
    formChangedDescription();
    fetchDescription();
    handleClose();
  }  
  const postMethod = async(data)=>{
    try {
      const response = await axios
        .post("api/budget/expense", data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });

      if (response) {
  

        let firstTimeData = Object(response.data).hasOwnProperty('data');
        let totalOverview = {};
        if(firstTimeData){
          totalOverview = {
            shortfall: response.data.data[0].shortfall,
            total_expenses: response.data.data[0].total_expenses,
            total_gross_income: response.data.data[0].total_gross_income,
            total_net_income: response.data.data[0].total_net_income,
          };
        }else{
          totalOverview = {
            shortfall: response.data.shortfall,
            total_expenses: response.data.total_expenses,
            total_gross_income: response.data.total_gross_income,
            total_net_income: response.data.total_net_income,
          };
        }
        
        setStats(totalOverview);

      }
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error.response.status);
      }
    }

  }


  const formChangedExpense = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-financial",
      title: "Expenses related to investment property",
      description: description,
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount !== '' ? formValues.actual_amount : 0,
      projected_amount: formValues.protected_amount !== '' ? formValues.protected_amount : 0,
    };
    postMethod(data);
  
  };

  const formChangedCommission = () => {
  const formValues = getValues();

    const data = {
      slug: "monthly-financial",
      title: "Commissions",
      description: description,
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_b !== '' ? formValues.actual_amount_b : 0,
      projected_amount: formValues.projected_amount_b !== '' ? formValues.projected_amount_b : 0,
    };

    postMethod(data);
  };

  const formChangedAttorney = () => {
  const formValues = getValues();

    const data = {
      slug: "monthly-financial",
      title: "Attorney’s Fees",
      description: description,
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_c !== '' ? formValues.actual_amount_c : 0,
      projected_amount: formValues.projected_amount_c !== '' ? formValues.projected_amount_c : 0,
    };

    postMethod(data);
  };

  const formChangedAccountant = () => {
  const formValues = getValues();

    const data = {
      slug: "monthly-financial",
      title: "Accountant’s Fees",
      description: description,
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_d !== '' ? formValues.actual_amount_d : 0,
      projected_amount: formValues.projected_amount_d !== '' ? formValues.projected_amount_d : 0,
    };

    postMethod(data);
  };

  const formChangedProffesional = () => {
  const formValues = getValues();

    const data = {
      slug: "monthly-financial",
      title: "Other professional fees",
      description: description,
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_e !== '' ? formValues.actual_amount_e : 0,
      projected_amount: formValues.projected_amount_e !== '' ? formValues.projected_amount_e : 0,
    };

    postMethod(data);
  };

  const formChangedSavings = () => {
  const formValues = getValues();

    const data = {
      slug: "monthly-financial",
      title: "Savings Reserve",
      description: description,
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_f !== '' ? formValues.actual_amount_f : 0,
      projected_amount: formValues.projected_amount_f !== '' ? formValues.projected_amount_f : 0,
    };
    postMethod(data);
  };

  const formChangedDescription = () => {
  const formValues = getValues();

    const data = {
      slug: "monthly-financial",
      description: formValues.description,
      client_case_id: client_case_id,
    };

    axios
      .post("api/budget/description/store", data)
      .then((res) => {
      
      })
      .catch((err) => {
        if ((err && "Error", err));
      });
  };
  
  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + client_case_id + "/" + "monthly-financial"
    )
      .then((res) => {
        setDescription(res.data.data);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };
  useEffect(() => {
    fetchDescription();
  }, []);

  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Monthly Financial Expense
        </DialogTitle>
        <Divider />

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Row className="mb-4">
              <Col>
                <Form.Label className="form-control border-0">
                  Expense
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="form-control border-0">
                  Actual amount
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="form-control border-0">
                  Projected (future) amount
                </Form.Label>
              </Col>
            </Row>

            <div>
              <Form className="m-3">
                <Row>
                  <Col>
                    <Form.Label className="form-control border-0">
                      Expenses related to investment property
                    </Form.Label>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="actual_amount"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          expense.length <= 0 ? 0 : expense[0].actual_amount
                        }
                        {...register("actual_amount", { required: true })}
                        // onBlur={formChangedExpense}
                      />
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="protected_amount"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          expense.length <= 0 ? 0 : expense[0].projected_amount
                        }
                        {...register("protected_amount", { required: true })}
                        // onBlur={formChangedExpense}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Form>
              <Form className="m-3">
                <Row>
                  <Col>
                    <Form.Label className="form-control border-0">
                      Commissions
                    </Form.Label>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        min={1}
                        name="actual_amount_b"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          comissions.length <= 0
                            ? 0
                            : comissions[0].actual_amount
                        }
                        {...register("actual_amount_b", { required: true })}
                        // onBlur={formChangedCommission}
                      />
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="projected_amount_b"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          comissions.length <= 0
                            ? 0
                            : comissions[0].projected_amount
                        }
                        {...register("projected_amount_b", { required: true })}
                        // onBlur={formChangedCommission}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Form>
              <Form>
                <Row>
                  <Col>
                    <Form.Label className="form-control border-0">
                      Attorney’s Fees
                    </Form.Label>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="actual_amount_c"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          attorney.length <= 0 ? 0 : attorney[0].actual_amount
                        }
                        {...register("actual_amount_c", { required: true })}
                        // onBlur={formChangedAttorney}
                      />
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="projected_amount_c"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          attorney.length <= 0
                            ? 0
                            : attorney[0].projected_amount
                        }
                        {...register("projected_amount_c", { required: true })}
                        // onBlur={formChangedAttorney}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Form>
              <Form className="m-3">
                <Row>
                  <Col>
                    <Form.Label className="form-control border-0">
                      Accountant’s Fees
                    </Form.Label>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="actual_amount_d"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          accountant.length <= 0
                            ? 0
                            : accountant[0].actual_amount
                        }
                        {...register("actual_amount_d", { required: true })}
                        // onBlur={formChangedAccountant}
                      />
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="projected_amount_d"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          accountant.length <= 0
                            ? 0
                            : accountant[0].projected_amount
                        }
                        {...register("projected_amount_d", { required: true })}
                        // onBlur={formChangedAccountant}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Form>
              <Form className="m-3">
                <Row>
                  <Col>
                    <Form.Label className="form-control border-0">
                      Other professional fees
                    </Form.Label>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="actual_amount_d"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          others.length <= 0 ? 0 : others[0].actual_amount
                        }
                        {...register("actual_amount_e", { required: true })}
                        // onBlur={formChangedProffesional}
                      />
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="projected_amount_d"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          others.length <= 0 ? 0 : others[0].projected_amount
                        }
                        {...register("projected_amount_e", { required: true })}
                        // onBlur={formChangedProffesional}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Form>
              <Form className="m3">
                <Row>
                  <Col>
                    <Form.Label className="form-control border-0">
                      Savings Reserve
                    </Form.Label>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="actual_amount_f"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          savings.length <= 0 ? 0 : savings[0].actual_amount
                        }
                        {...register("actual_amount_f", { required: true })}
                        // onBlur={formChangedSavings}
                      />
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextField
                        name="projected_amount_f"
                        helperText="Some important text"
                        className="custom-border"
                        variant="outlined"
                        type="number"
                        defaultValue={
                          savings.length <= 0 ? 0 : savings[0].projected_amount
                        }
                        {...register("projected_amount_f", { required: true })}
                        // onBlur={formChangedSavings}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col>
                  <form>
                    <Form.Label className="form-control border-0">
                      *If you feel that you need to explain any of the above
                      further, please use the space below to do so.
                    </Form.Label>
                    <TextareaAutosize
                      className={`form-control`}
                      aria-label="minimum height"
                      minRows={4}
                      name="description"
                      type="text"
                      defaultValue={description ? description.description : ""}
                      {...register("description", { required: true })}
                      // onBlur={formChangedDescription}
                    />
                  </form>
                </Col>
              </Row>
              </div>
          </DialogContentText>
        </DialogContent>
             <DialogActions>
              <button
                id="kt_login_signin_submit"
                onClick={handleSubmit}
                className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
              >
                <span>Submit</span>
              </button>
            <Button
              onClick={handleCloseModal}
              className={`btn custom-cancel-button font-weight-bold px-9 py-4 my-3`}
            >
              Cancel
            </Button>
        </DialogActions>   
      </Dialog>
    </div>
  );
}
