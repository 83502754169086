import React, { useEffect, useState } from "react";
import { Button, Divider, InputAdornment, TextField } from '@material-ui/core';
import { CheckIfEmailExists, FetchPricingDetails } from "../../../../Functions/HelpersWithAPI";
import { useFormik } from "formik";
import * as yup from 'yup';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import BillingElements from "./BillingElements";
import Axios from "axios";
import { Dialog, DialogContent } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from "react-router-dom";

const validationSchema = yup.object().shape({
    company_name: yup.string(),
    firstname: yup.string(),
    lastname: yup.string(),
    email: yup.string().email('Email is required'),
    password: yup.string().min(8, 'Required Length is 8.')
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function AccountRegistration({price_key}){
    const [verifyingEmail, setVerifyingEmail] = useState(false);
    const [emailAvailable, setEmailAvailable] = useState(null);
    const [stepValue, setStepValue] = useState(1);
    const [isFreemium, setIsFreemium] = useState(false);
    const [pricingDetails, setPricingDetails] = useState(null);
    const [success, setSuccess] = useState(false);
    const history = useHistory();
    
    const handleVerifyEmail = async (email) => {
        setVerifyingEmail(true);
        try {
            const response = await CheckIfEmailExists(email);
            setVerifyingEmail(false);
            console.log(email, response?.data?.data, !Boolean(response?.data?.data));
            setEmailAvailable(!Boolean(response?.data?.data));
            return response?.data?.data;
        } catch (error) {
            setEmailAvailable(null);
            setVerifyingEmail(false);
            return false;
        }
    };

    useEffect(() => {
        if(!emailAvailable){
            setTimeout(() => {
                setEmailAvailable(null);
            }, 3000);
        }
    }, [emailAvailable]);

    const handleSubmit = async (values) => {
        formik.setSubmitting(true);
        await Axios.post("api/subscribe", values)
        .then(() => {
            setSuccess(true);
        })
        .finally(() => {
            formik.setSubmitting(false);
        });
    };
    
    const formik = useFormik({
        initialValues:{
            company_name: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            priceKey: price_key
        },
        initialErrors:{
            email: 'Required',
            password: 'Required'
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });
    
    const handleButtonValidation = () => {
        let stripe_price = FetchPricingDetails(price_key);
        setPricingDetails(stripe_price);
        if(stepValue === 1 && emailAvailable && Object.keys(formik.errors).length === 0 ){
            if(stripe_price.name === 'Freemium'){
                setIsFreemium(true);
                setStepValue(prevState => prevState + 2);
            } else {
                setStepValue(prevState => prevState + 1);
            }
        }
        if(stepValue === 2 && formik.values.payment_method){
            setStepValue(prevState => prevState + 1);
        }
        if(stepValue === 3){
            setStepValue(prevState => prevState + 1);
        }
    };
    
    useEffect(() => {
        if(success){
            setTimeout(() => {
                history.push('/auth/login')
            }, 3000);
        }
    }, [success]);
    return(
        <form onSubmit={formik.handleSubmit} className="d-flex flex-row flex-wrap mt-3 col-12 px-0" style={{gap: '8px'}}>
            {stepValue === 1 && (
                <>
                <div className="d-flex flex-column align-self-left col-12 px-0">
                    <label htmlFor="company" className="font-weight-bolder">Lawfirm/Business Name</label>
                    <TextField variant="outlined" className="bg-white text-black" placeholder="Enter your Lawfirm Name" fullWidth {...formik.getFieldProps('company_name')}/>
                </div>
                <div className="d-flex flex-row col-12 px-0">
                    <div className="col-6 pl-0">
                        <div className="d-flex flex-column">
                            <label htmlFor="company" className="font-weight-bolder">First Name</label>
                            <TextField variant="outlined" className="bg-white text-black" placeholder="Enter your First Name" fullWidth {...formik.getFieldProps('firstname')}/>
                        </div>
                    </div>
                    <div className="col-6 pr-0">
                        <div className="d-flex flex-column">
                            <label htmlFor="company" className="font-weight-bolder">Last Name</label>
                            <TextField variant="outlined" className="bg-white text-black" placeholder="Enter your Last Name" fullWidth {...formik.getFieldProps('lastname')}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column col-12 px-0">
                    <label htmlFor="company" className="font-weight-bolder">Email</label>
                    {emailAvailable == false && <p className="text-danger">Email already exists!</p>}
                    <TextField
                        variant="outlined"
                        className={`bg-white text-black ${emailAvailable == null ? "" : emailAvailable == true ? "border border-success" : "border border-danger"}`}
                        placeholder="Enter your Email"
                        fullWidth
                        {...formik.getFieldProps('email')}
                        onBlur={(e) => handleVerifyEmail(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                {verifyingEmail && <div className="spinner-border spinner-border-md"></div>}
                            </InputAdornment>
                        }}
                        disabled={verifyingEmail}
                        />
                </div>
                <div className="d-flex flex-column col-12 px-0">
                    <label htmlFor="company" className="font-weight-bolder">Password</label>
                    {formik.touched?.password && formik.errors?.password && <p className="text-danger">{formik.errors?.password}</p>}
                    <TextField type="password" variant="outlined" className="bg-white text-black" placeholder="Enter your password" fullWidth {...formik.getFieldProps('password')}/>
                </div>
                </>
            )}
            {stepValue === 2 && (
                <>
                <div className="d-flex flex-column align-self-left col-12 px-0">
                    <label htmlFor="company" className="font-weight-bolder">Billed to</label>
                    <TextField variant="outlined" className="bg-white text-black" placeholder="Enter your Card Name" fullWidth {...formik.getFieldProps('card_name')}/>
                </div>
                <Elements stripe={stripePromise} >
                    <BillingElements formik={formik} />
                </Elements>
                </>
            )}
            {stepValue === 3 && (
                <>
                <div className="d-flex flex-column align-self-left col-12 px-0">
                    <h2 htmlFor="company" className="font-weight-bolder">Summary</h2>
                    <Divider className="mt-3 mb-2" />
                    <h2 className="font-weight-bold text-dark">Account & Billing Details</h2>
                    <div className="d-flex flex-row">
                        <h6 className="font-weight-bolder text-grey">Lawfirm:</h6>
                        <p className="font-weight-light text-grey ml-auto">{formik.values.company_name}</p>
                    </div>
                    <div className="d-flex flex-row">
                        <h6 className="font-weight-bolder text-grey">Email:</h6>
                        <p className="font-weight-light text-grey ml-auto">{formik.values.email}</p>
                    </div>
                    {!isFreemium && (
                        <div className="d-flex flex-row">
                            <h6 className="font-weight-bolder text-grey">Billed to:</h6>
                            <p className="font-weight-light text-grey ml-auto">{formik.values.card_name}</p>
                        </div>
                    )}
                    <Divider className="mt-3 mb-2" />
                    <h2 className="font-weight-bold text-dark">Subscription Details</h2>
                    <div className="d-flex flex-row">
                        <h6 className="font-weight-bolder text-grey">Subscription:</h6>
                        <p className="font-weight-light text-grey ml-auto">{pricingDetails.name}</p>
                    </div>
                    <div className="d-flex flex-row">
                        <h6 className="font-weight-bolder text-grey">Price:</h6>
                        <p className="font-weight-light text-grey ml-auto">${pricingDetails.amount}/month</p>
                    </div>
                    <div className="d-flex flex-row">
                        <h6 className="font-weight-bolder text-grey">Details:</h6>
                        {/* //TODO: Fetch the Subscription Details. */}
                        <div className="d-flex flex-column ml-auto">
                            <p className="font-weight-light text-grey ml-auto">Free Premium Access for 30 days.</p>
                            <ul className="font-weight-normal text-grey" style={{paddingLeft: '20px'}}>
                                <li>Budget Module</li>
                                <li>Children Module</li>
                                <li>Estate Module</li>
                                <li>Estate Division Module</li>
                                <li>Timeline Module</li>
                            </ul>
                            <p className="font-weight-light text-grey ml-auto">24/7 Priority Customer Support.</p>
                            <p className="font-weight-light text-grey ml-auto">1 on 1 feature demo.</p>
                        </div>
                    </div>
                    <Divider className="mt-3 mb-2" />
                </div>
                </>
            )}
            <div className="d-flex flex-row col-12 px-0">
                {stepValue > 1 && (
                    <div className="align-self-start">
                        <Button type="button" variant="contained" color="primary" size="large" onClick={() => setStepValue(prevState => prevState - 1)} disabled={verifyingEmail || !(emailAvailable && Object.keys(formik.errors).length === 0)}>Prev</Button>
                    </div>
                )}
                {stepValue !== 3 && (
                    <div className="ml-auto">
                        <Button type="button" variant="contained" color="primary" size="large" onClick={() => handleButtonValidation()} disabled={verifyingEmail}>
                        Next
                        </Button>
                    </div>
                )}
                {stepValue === 3 && (
                    <div className="ml-auto">
                        <Button type="submit" variant="contained" color="primary" size="large" disabled={formik.isSubmitting}>
                        Pay
                        </Button>
                    </div>
                )}
            </div>
            <Dialog open={formik.isSubmitting || success} maxWidth="md" fullScreen>
                <DialogContent className="d-flex flex-column justify-content-center align-items-center">
                    {!success && (
                        <div className='spinner-border spinner-border-lg text-gray' style={{width:"5rem", height: "5rem"}}>
                        
                        </div>
                    )}
                    {success && (
                        <>
                        <CheckIcon className="text-success" style={{ fontSize: 180 }} />
                        <h1 className="display-3 font-weight-bold">🎉CONGRATULATIONS🎉</h1>
                        <p className="font-weight-bold">You'll be redirected shortly to login....</p>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </form>
    );
};