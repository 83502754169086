import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { EstateContext } from "../../../../hooks/ContextStore";
import Loading from "../../../pages/Loading";
import FileUpload from "../Components/FileUpload";
import CreateAnnuities from "./AnnuitiesModal/Create";
import DeleteAnnuities from "./AnnuitiesModal/Delete";
import EditAnnuities from "./AnnuitiesModal/Edit";
import CreateLifeInsurance from "./LifeInsuranceModal/Create";
import DeleteLifeInsurance from "./LifeInsuranceModal/Delete";
import EditLifeInsurance from "./LifeInsuranceModal/Edit";
import ShowNoteComponent from "./ShowNoteComponent";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function LifeInsuranceRow(props) {
  const { row, setInsurances, client_case_id } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name ?? "N/A"}
        </TableCell>
    
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.spouse_sp ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditLifeInsurance
                client_case_id={client_case_id}
                row={row}
                setInsurances={setInsurances}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteLifeInsurance row={row} setInsurances={setInsurances} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'life-insurance'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Insurance Company :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Policy # :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.policy_num}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of Insured:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.insured}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Type :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.type}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Amount payable on Death :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.payable}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Designated beneficiary :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.beneficiary}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Cash surrender value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value} as of ({moment(row.value_date).format("L")})
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function LifeInsurance() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [insurances, setInsurances] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/insurance/" + client_case_id)
      .then((res) => {
        setInsurances(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insurances.map((row) => (
                <LifeInsuranceRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setInsurances={setInsurances}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function AnnuitiesRow(props) {
  const { row, client_case_id, setInsurances } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name ?? ""}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditAnnuities
                row={row}
                client_case_id={client_case_id}
                setInsurances={setInsurances}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteAnnuities row={row} setInsurances={setInsurances} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'annuities'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of company :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Policy # :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.policy}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of annuitant:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.annuitant}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Type of distribution :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.distribute_type}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Amount to be distributed/period :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.distributed}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Annuitization date :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.annuitization}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Cash surrender value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.cash_surrender}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Designated beneficiary :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.beneficiary}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Current value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value} as of ({moment(row.value_date).format("L")})
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Annuities() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [insurances, setInsurances] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetch_data = () => {
    setLoading(true);
    Axios.get("api/estate/annuities/" + client_case_id)
      .then((res) => {
        setInsurances(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>Annuities </h6>
          <div className="ml-auto">
            <CreateAnnuities
              client_case_id={client_case_id}
              setInsurances={setInsurances}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insurances.map((row) => (
                <AnnuitiesRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setInsurances={setInsurances}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <Loading />}
      </div>
    </>
  );
}

function InsuranceAnnuities() {

  return (
    <>
      <div id="real-property" className={`card h-100 card-custom `}>
        {/* <div className="card-header border-0 mt-3">
          <h3 class="card-title align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Life Insurance & Annuities
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Life Insurance & Annuities
            </span>
          </h3>
        </div>
        <hr /> */}
        <LifeInsurance /> 
      </div>
    </>
  );
}

export default InsuranceAnnuities;
