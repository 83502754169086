import Axios from "axios";

/**
 * Check if email is already existing.
 * @param {string} email
 * @returns {boolean}
 */
export const CheckIfEmailExists = async (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex) return false;
    return await Axios.get(`/api/email/verify/${email}`);
};

/**
 * Get Subscription Details.
 * @param {string} stripe_price_id
 * TODO: Fetch subscription details from database.
 */
export const FetchPricingDetails = (stripe_price_id) => {
    if(stripe_price_id === 'price_1QoNB5ChLtarUozxc431d3fF'){
        return {
            name: 'Freemium',
            amount: 0
        };
    }
    if(stripe_price_id === 'price_1LgzVaChLtarUozxpNROLlxD'){
        return {
            name: 'Clientwise Premium',
            amount: 169
        };
    }
    if(stripe_price_id === 'price_1LgzVQChLtarUozxwicySept'){
        return {
            name: 'Clientwise Standard',
            amount: 129
        };
    }
    if(stripe_price_id === 'price_1LgzVGChLtarUozxSmAFcDXR'){
        return {
            name: 'Clientwise Basic',
            amount: 99
        };
    }
    return {
        name: 'Invalid',
        amount: 9999
    }
};