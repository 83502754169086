import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import AccountRegistration from './components/AccountRegistration';
import './style.scss';


export default function Registration(props){
    const stripe_price_id = useState(props.match.params.priceKey);
    return(
        <div id="subscription-page" className="d-flex flex-row flex-wrap" style={{minHeight: '100vh'}}>
            <div className="col-sm-12 col-md-4 bg-white d-flex flex-column align-items-center justify-content-center">
                <Link to="/" className="flex-column-auto m-5 pb-lg-0 pb-10 d-flex justify-content-center">
                    <img
                    alt="Logo"
                    className="max-h-70px"
                    src={toAbsoluteUrl(
                        "/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png"
                    )}
                    />
                </Link>
            </div>
            <div className="col-sm-12 col-md-8 d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex flex-column justify-content-center align-items-center p-5 form-content">
                    <div className="d-flex flex-column align-self-start">
                        <h1 className="display-3 m-0 font-weight-bolder text-dark">Start your 30-Day Trial</h1>
                        <p className="text-gray font-weight-light">Case Management System for Smarter Legal Teams.</p>
                    </div>
                    <AccountRegistration price_key={stripe_price_id?.[0]}/>
                </div>
            </div>
        </div>
    )
};