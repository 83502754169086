import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useEffect, useState, createContext } from "react";

export const LawfirmContext               = createContext();
export const SubscriptionContext          = createContext();
export const CardInfoContext              = createContext();
export const LoggedInUserContext          = createContext();
export const BudgetContext                = createContext();
export const EstateContext                = createContext();
export const EstateDivisionContext        = createContext();
export const SnackBarOptionsContext       = createContext();
export const ClientPropertyContext = createContext();
export const SpousePropertyContext = createContext();
export const BothPropertyContext = createContext();
export const TimelineCategoriesContext = createContext();
export const FillContext = createContext();
export const ShowTourContext = createContext();

const ContextStore = ({ children }) => {
  
  const [budget, setBudget]                   = useState();
  const [category, setCategory]               = useState(null);
  const encryptStorage                        = new EncryptStorage("secret-key");
  const decypt_data                           = encryptStorage.getItem("case_details");
  const data                                  = decypt_data;
  const client_case_id                        = data && data.case ? data.case.id : null;
  const [snackbarOptions, setSnackbarOptions] = useState({show: false, title:''});
  // const [banks, setBanks] = useState([]);
  const [clientproperties, setclientProperties] = useState([]);
  const [spouseproperties, setspouseProperties] = useState([]);
  const [bothproperties, setbothProperties] = useState([]);
  const [timelineCategories, setTimelineCategories] = useState([]);
  const [showTour, setShowTour] = useState(false);
  
  const [fill, setFill] = useState(null);


  let fetch_budget_total = () => {
    Axios.get("api/budget/stats/" + client_case_id)
      .then((res) => {
        setBudget(res.data);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };

  let fetch_estate_category = () => {
    if(client_case_id === null) return;
    Axios.get("api/estate/category/" + client_case_id)
      .then((res) => {
        setCategory(res.data.data[0]);
        encryptStorage.setItem('EstateCategories', res.data.data[0]);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };

let fetch_clientproperty = () => {
  Axios.get("api/estate/asset/index/" + client_case_id + "/1")
    .then((res) => {
      setclientProperties(res.data.data);
    })
    .catch((err) => {
      console.log("error", err);
    });
};

let fetch_spouseproperty = () => {
  Axios.get("api/estate/asset/index/" + client_case_id + "/2")
    .then((res) => {
      setspouseProperties(res.data.data);
    })
    .catch((err) => {
      console.log("error", err);
    });
};

let fetch_bothproperty = () => {
  Axios.get("api/estate/asset/index/" + client_case_id + "/3")
    .then((res) => {
      setbothProperties(res.data.data);
    })
    .catch((err) => {
      console.log("error", err);
    });
};

const fetch_timeline_categories = () => {
    Axios.get(`/api/v2/timeline/events/categories/list/${client_case_id}`)
    .then(res => {
      setTimelineCategories(res.data);
    })
    .catch(err => {
        console.log(err);
    });
};

const fetch_estate_data = () => {
  Axios.get("api/estate/categories/" + client_case_id)
    .then((res) => {
      setFill(res.data);
    })
    .catch((err) => {
      if ((err && "Error", err)) console.log("Error", err.response);
    });
}

  useEffect(() => {
    fetch_estate_category();
    fetch_budget_total();
    fetch_clientproperty();
    fetch_spouseproperty();
    fetch_bothproperty();
    fetch_timeline_categories();
    fetch_estate_data();
  }, [client_case_id]);
  
  return (
    <BudgetContext.Provider value={[budget, setBudget]}>
      <EstateContext.Provider value={[category, setCategory]}>
        <FillContext.Provider value={[fill, setFill]}>
        <ClientPropertyContext.Provider value={[clientproperties,setclientProperties]}>
          <SpousePropertyContext.Provider value={[spouseproperties,setspouseProperties]}>
            <BothPropertyContext.Provider value={[bothproperties,setbothProperties]}>
              <SnackBarOptionsContext.Provider value={[snackbarOptions, setSnackbarOptions]}>
                <TimelineCategoriesContext.Provider value={[timelineCategories, setTimelineCategories]}>
                  <ShowTourContext.Provider value={[showTour, setShowTour]}>
                    {children}
                  </ShowTourContext.Provider>
                </TimelineCategoriesContext.Provider>
              </SnackBarOptionsContext.Provider>
            </BothPropertyContext.Provider>
          </SpousePropertyContext.Provider>
        </ClientPropertyContext.Provider>
        </FillContext.Provider>
      </EstateContext.Provider>
    </BudgetContext.Provider>
  );
};

export default ContextStore;
