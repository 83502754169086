import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useLocation } from 'react-router-dom'
import { ShowTourContext } from "../../../hooks/ContextStore";

export default function ShowTour(){
    const [video, setVideo] = useState("");
    const location = useLocation();
    const [showTour, setShowTour]  = useContext(ShowTourContext);
    
    const handleFetchVideo = async () => {
        let module_slug = "";
        if(location.pathname.includes('basics')){
            module_slug = "basics";
        }
        if(location.pathname.includes('budget')){
            module_slug = "budget";
        }
        if(location.pathname.includes('children')){
            module_slug = "children";
        }
        if(location.pathname.includes('estate')){
            module_slug = "estate";
        }
        if(location.pathname.includes('timeline')){
            module_slug = "timeline";
        }
        await Axios.get(`api/console/get_module/${module_slug}`)
        .then(res => {
            if( res.data.video_url !== ""){
                setVideo(res.data.video_url);
            } else {
                setVideo("");
            }
        });
    };
    
    
    useEffect(() => {
        handleFetchVideo();
    }, [location.pathname]);
    
    return(
        <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '100vh', width: '100%', left: '0', top: '0', zIndex: '1', position: 'fixed', backdropFilter: 'blur(5px)'}}>
            <div className="card shadow-lg text-white" style={{minHeight: '300px', minWidth: '300px', background: '	#3699ff'}}>
                <div className="card-body d-flex flex-column">
                    {video && (
                        <video src={video} autoplay controls style={{height: "300px", width: "300px"}}></video>
                    )}
                    <button className="btn custom-blue-button-dashboard" onClick={() => setShowTour(false)}>Close</button>
                </div>
            </div>
        </div>
    );
};